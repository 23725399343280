import { Box, Grid, Typography, IconButton, Avatar } from "@material-ui/core";
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import {
    makeStyles,
    createStyles,
    Theme,
    withStyles,
} from "@material-ui/core/styles";
import React, { FunctionComponent, ReactNode, useState } from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import CustomBar from "./Bar";
import SingleBar from "./SingleBar"
// @ts-ignore
import engageImage from "../../blocks/visualanalytics/assets/engaging.png";
// @ts-ignore
import tiredImage from "../../blocks/visualanalytics/assets/tired.png";
// @ts-ignore
import participationImage from "../../blocks/visualanalytics/assets/participation.png";
import EngagementMeter from "./EngagementMeter";
import CustomMeter from "./CustomMeter";

interface EngagementBoxProps {
    most: any[],
    less: any[],
    engagement: any,
    boxEngOpen: any;
    handleBoxEngOpen: any;
}


const EngagementBox = ({ most, less, engagement, boxEngOpen,handleBoxEngOpen }: EngagementBoxProps) => {
    const classes = useStyles();
    const [open, setOpen] = useState({})

    return (
        /* @ts-ignore */
        <Box style={{ marginBottom: "10px" }}>
            {/* @ts-ignore */}
            <Box style={{ border: "1px solid rgb(72 72 72)", borderRadius: "10px" }}>
                <List>
                    <ListItem button onClick={() => handleBoxEngOpen(boxEngOpen)} classes={{root: classes.removePadding}}>
                        {/* @ts-ignore */}
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}
                        >
                            <Typography
                                style={{
                                    fontFamily: 'SFPro-Regular',
                                    fontSize: '16px',
                                    color: '#ffffff',
                                    marginRight: '40px'
                                }}
                            >
                                Engagement
                            </Typography>
                            {/* @ts-ignore */}
                            {boxEngOpen ? <ExpandMoreRoundedIcon style={{ color: "white" }} fontSize="medium" /> : <ChevronRightRoundedIcon style={{ color: "white" }} fontSize="medium" />}
                        </Box>
                    </ListItem>
                </List>
                {/* @ts-ignore */}
                <Collapse in={boxEngOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem style={{ color: "#ffffff", backgroundColor: "#1c1b1b", borderRadius: "0 0 10px 10px" }}>
                            {/* @ts-ignore */}
                            <Box style={{ height: "150px", display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                {/* @ts-ignore */}
                                <Box >
                                    <Typography style={{ color: "white", fontSize: "16px", fontFamily: "SFPro-Regular" }}>Most interactive</Typography>
                                    <AvatarGroup max={4}>
                                        {
                                            most.map((img) => (
                                                <Avatar style={{ height: '30px', width: '30px' }} alt="most_interactive" src={img} />
                                            ))
                                        }
                                    </AvatarGroup>
                                    <Typography style={{ color: "white", fontSize: "16px", fontFamily: "SFPro-Regular" }}>Less interactive</Typography>
                                    <AvatarGroup max={4}>
                                        {
                                            less.map((img) => (
                                                <Avatar style={{ height: '30px', width: '30px' }} alt="most_interactive" src={img} />
                                            ))
                                        }
                                    </AvatarGroup>
                                </Box>
                                {/* @ts-ignore */}
                                <Box>
                                    {/* <EngagementMeter level={3}/> */}
                                    <CustomMeter level={4} energy={engagement}/>
                                    <Typography style={{ color: "#0CC8A8", fontSize: "16px", fontFamily: "SFPro-Bold", marginTop: "10px", textAlign: "center" }}>Engagement Meter</Typography>
                                </Box>
                            </Box>
                        </ListItem>
                    </List>
                </Collapse>
            </Box>
        </Box>
    )
}
export default EngagementBox;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        
        removePadding: {
           paddingTop: "0",
           paddingBottom: "0"
        },
        avatarImage: {
            '&: .MuiAvatar-root':{
             height: '30px',
             width: "30px"   
            }
        }
    })
);