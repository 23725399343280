import React from 'react';

interface BarProps {
    bgcolor: string;
    progress: string | undefined;
    height: number;
    align?: string;
}


const Progress_bar = ({ bgcolor, progress, height, align = "horizontal" }: BarProps) => {

    const Parentdiv = {
        height: align === "horizontal" ? height : "90%",
        width: align === "horizontal" ? '100%' : height,
        // backgroundColor:  'whitesmoke',
        backgroundColor: align === "horizontal" ? 'black' : `${bgcolor}`,
        border: `2px solid ${bgcolor}`,
        // margin: 50,
        borderRadius: "5px"
    }

    const Childdiv = {
        height: align == "horizontal" ? '100%' : `${progress}%`,
        width: align == "horizontal" ? `${progress}%` : "100%",
        backgroundColor: align == "horizontal" ? `${bgcolor}` : "black",
        // backgroundColor: "yellow",

        textAlign: 'right'
    }

    const progresstext = {
        padding: 10,
        color: 'black',
        fontWeight: 900
    }

    return (
        <div style={Parentdiv}>
            {/* @ts-ignore */}
            <div style={Childdiv}>
            </div>
        </div>
    )
}

export default Progress_bar;
