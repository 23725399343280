import moment from "moment";
// @ts-ignore
import notesImage from "../../blocks/visualanalytics/assets/notesImg.png";
// @ts-ignore
import engagingImage from "../../blocks/visualanalytics/assets/engaging.png";
// @ts-ignore
import tiredImage from "../../blocks/visualanalytics/assets/tired.png";
// @ts-ignore
import most1Image from "../../blocks/visualanalytics/assets/most1.png";
// @ts-ignore
import most2Image from "../../blocks/visualanalytics/assets/most2.png";
// @ts-ignore
import most3Image from "../../blocks/visualanalytics/assets/notesImg.png";
// @ts-ignore
import less1Image from "../../blocks/visualanalytics/assets/less.png";
// @ts-ignore
import downImage from "../../blocks/visualanalytics/assets/down.png";
// @ts-ignore
import engDropImage from "../../blocks/visualanalytics/assets/egDrop.png";
// @ts-ignore
import engRiseImage from "../../blocks/visualanalytics/assets/egRise.png";
// @ts-ignore
import keyMomentImage from "../../blocks/visualanalytics/assets/keym.png";
// @ts-ignore
import kudoImage from "../../blocks/visualanalytics/assets/kudo.png";
// @ts-ignore
import miscImage from "../../blocks/visualanalytics/assets/misc.png";
// @ts-ignore
import riseImage from "../../blocks/visualanalytics/assets/rise.png";
// @ts-ignore
import tipImage from "../../blocks/visualanalytics/assets/tip.png";


export const palette = {
    primary: {
        text: "#ffffff",
        bg: "#171617"
    },
    secondary: {
        bg: "black",
        text: "#0CC8A8"
    },
    fontFamily: {
        head: "SFPro-Regular",
        main: "SFPro-Regular"
    }
};

export const hourMinutesSeconds = (date: string) => {
    if (!date) return;
    return date.substring(11,19);
}

export interface fakeNotesType {
    id: string;
    time: string;
    pImg: string;
    pName: string;
    emoji: string;
    status: string;
    bar: number;
    statsDesc: string;
    eventName: string;
    eventIcon: string;
    eventDesc: string;
}

export const notesData: fakeNotesType[] = [
    {
        id: "1",
        time: "09:30:22",
        pImg: notesImage,
        pName: "Christian Russell",
        emoji: engagingImage,
        status: "Engaging",
        bar: 1,
        eventName: "Energy Rise",
        eventIcon: riseImage,
        statsDesc: "Yippie! You are engaging with them really good, try to keep it up with the same energy.",
        eventDesc: "Overall meeting energy went up."
    },
    {
        id: "2",
        time: "09:30:22",
        pImg: notesImage,
        pName: "Christian Russell",
        emoji: tiredImage,
        status: "Tired",
        bar: 2,
        eventName: "Tip",
        eventIcon: tipImage,
        statsDesc: "You seemed tired! Take time re-energize yourself. A good snack, coffee, or a short walk can refresh you.",
        eventDesc: "You seemed tired! Take time re-energize yourself. A good snack, coffee, or a short walk can refresh you."
    },
    {
        id: "3",
        time: "09:30:22",
        pImg: notesImage,
        pName: "Christian Russell",
        emoji: engagingImage,
        status: "Engaging",
        bar: 1,
        eventName: "Kudo",
        eventIcon: kudoImage,
        statsDesc: "Yippie! You are engaging with them really good, try to keep it up with the same energy.",
        eventDesc: "Yippie! You are engaging with them really good, try to keep it up with the same energy."
    },
    {
        id: "4",
        time: "09:30:22",
        pImg: notesImage,
        pName: "Christian Russell",
        emoji: engagingImage,
        status: "Engaging",
        bar: 1,
        eventName: "Key moment",
        eventIcon: keyMomentImage,
        statsDesc: "Yippie! You are engaging with them really good, try to keep it up with the same energy.",
        eventDesc: "Report Established."
    },
    {
        id: "5",
        time: "09:30:22",
        pImg: notesImage,
        pName: "Christian Russell",
        emoji: tiredImage,
        status: "Tired",
        bar: 2,
        eventName: "Misc.",
        eventIcon: miscImage,
        statsDesc: "You seemed tired! Take time re-energize yourself. A good snack, coffee, or a short walk can refresh you.",
        eventDesc: "You are speaking from last 15 minutes, and others are on mute."
    },
    {
        id: "6",
        time: "09:30:22",
        pImg: notesImage,
        pName: "Christian Russell",
        emoji: engagingImage,
        status: "Engaging",
        bar: 1,
        eventName: "Engagement Rise",
        eventIcon: engRiseImage,
        statsDesc: "",
        eventDesc: "Sudden engagement boost."
    },
    {
        id: "7",
        time: "09:30:22",
        pImg: notesImage,
        pName: "Christian Russell",
        emoji: tiredImage,
        status: "Tired",
        bar: 2,
        eventName: "Engagement drop",
        eventIcon: engDropImage,
        statsDesc: "",
        eventDesc: "There is drop in engagement from past 5 minutes."
    },
    {
        id: "8",
        time: "09:30:22",
        pImg: notesImage,
        pName: "Christian Russell",
        emoji: engagingImage,
        status: "Engaging",
        bar: 1,
        eventName: "Energy down",
        eventIcon: downImage,
        statsDesc: "",
        eventDesc: "30% of attendee's energy drop."
    },
    {
        id: "9",
        time: "09:30:22",
        pImg: notesImage,
        pName: "Christian Russell",
        emoji: engagingImage,
        status: "Engaging",
        bar: 1,
        eventName: "Energy down",
        eventIcon: downImage,
        statsDesc: "",
        eventDesc: "30% of attendee's energy drop."
    },
    {
        id: "10",
        time: "09:30:22",
        pImg: notesImage,
        pName: "Christian Russell",
        emoji: tiredImage,
        status: "Tired",
        bar: 2,
        eventName: "Kudo",
        eventIcon: kudoImage,
        statsDesc: "",
        eventDesc: "Yippie! You are engaging with them really good, try to keep it up with the same energy."
    },
]

export const fakeImageData = {
    most: [most1Image, most2Image, most3Image],
    less: [less1Image]
}
