import PostCreationCommonController from './PostCreationCommonController'

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export default class PostCreationController extends PostCreationCommonController {
  // Customizable Area Start
  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  
  // Customizable Area End
}
