import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End
export const configJSON = require("./config.js");
export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    classes: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    imageLoading: boolean;
    password: boolean;
    email: string;
    isOTP: boolean;
    windowWidth: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ForgotPasswordController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // getName(MessageEnum.NavigationToSingleArticle),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            imageLoading: true,
            password: true,
            email: "",
            isOTP: false,
            windowWidth: window.innerWidth,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {

        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId != null) {
                // if (apiRequestCallId == this.apiGetDataCallId) {
                //     if (responseJson && !responseJson.errors && responseJson.data) {
                //         if (responseJson.data.length === 0) {
                //             this.setState({
                //                 errorMsg: "Data Not Found",
                //                 loading: false,

                //             });
                //         } else {
                //             this.setState({
                //                 ...this.state,
                //                 errorMsg: "",
                //                 loading: false,
                //             });
                //         }
                //     } else {
                //         if (errorReponse === undefined) {
                //             this.setState({
                //                 errorMsg: "Something went wrong",
                //                 loading: false,
                //             });
                //         } else {
                //             this.setState({
                //                 errorMsg: errorReponse,
                //                 loading: false,
                //             });
                //         }
                //     }
                // }

            }
        }
        // Customizable Area End

    }
    // Customizable Area Start
    async componentDidMount() {
        window.addEventListener('resize', this.updateDimensions)
    }

    componentWillUnmount = (): any => {
        window.removeEventListener('resize', this.updateDimensions)
    }

    // Customizable Area End

    // Customizable Area Start

    updateDimensions = () => {
        this.setState({ windowWidth: window.innerWidth });
    }

    handleImageLoading = () => {
        this.setState({ imageLoading: false })
    }

    showPassword = (passoword: boolean) => {
        this.setState({ password: !passoword })
    }

    // Customizable Area End

}
