import { Box, Button, Grid, Typography, IconButton } from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
} from "@material-ui/core/styles";
import React from "react";
import { hourMinutesSeconds } from "./Utils"
// @ts-ignore
import TimeImage from "../../blocks/visualanalytics/assets/time.png";
// @ts-ignore
import logoImage from "../../blocks/visualanalytics/assets/logo.png";
interface UppNavProps{
    handleClick: () => void;
    handleJoin: ()=> void;
    totalParticipants: number;
    seconds: number;
    meetingIsInProgress: boolean;
   
}

const UppNav = ({ meetingIsInProgress, totalParticipants, seconds, handleJoin, handleClick }: UppNavProps) => {
    const classes = useStyles();
    const date = new Date(seconds * 1000).toISOString();
   
    return (
        /* @ts-ignore */
        <Box>
            <Grid container style={{ background: "linear-gradient(0deg, #212121 0%, #2e2e2e 100%)", height: "70px", alignItems: "center"}}>
                <Grid item md={4} style={{ padding: "0 20px" }}>
                {/* @ts-ignore */}
                    <Box style={{ display: "flex", justifyContent: "space-between", padding: "0 10px" }}>
                        {meetingIsInProgress && <Typography style={{ color: "#ffffff", fontFamily: "SFPro-Regular" }}>Total people - {totalParticipants}</Typography>}
                        {meetingIsInProgress && <Typography style={{ color: "#ffffff", fontFamily: "SFPro-Regular" }}>
                            <IconButton
                                style={{
                                    height: '15px',
                                    width: '15px',
                                    borderRadius: '3px',
                                }}
                            >
                                <img src={TimeImage} height={'15px'} />
                            </IconButton> {
                                hourMinutesSeconds(date)
                            }</Typography>}
                    </Box>
                </Grid>
                <Grid item md={8} style={{ padding: "0 20px", width: "100%" }} justifyContent="space-between" alignItems="center">
                    {/* @ts-ignore */}
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography className="setMarginLeft" style={{ marginLeft:"16%",color: "white", fontFamily: "SFPro-Bold" }}>
                            <IconButton
                                style={{
                                    height: '35px',
                                    width: '35px',
                                    borderRadius: '5px',
                                }}
                            >
                                <img src={logoImage} height={'35px'} />
                            </IconButton>
                            &nbsp;&nbsp;Dashboard
                        </Typography>
                        <Typography style={{ fontFamily: "SFPro-Regular" }}>
                            { meetingIsInProgress ? <Button onClick={handleClick} variant="outlined" style={{border: "1px solid #F1776C", borderRadius: "5px", textTransform: "initial"}} >
                                <Typography style={{ color: "#F1776C", fontFamily: "SFPro-Regular", fontSize: "13px" }}> 
                                    <img src={require("../../blocks/visualanalytics/assets/leaveIcon.png")} style={{width: "13px", paddingRight: "10px"}} />
                                    Leave Meeting
                                </Typography>
                            </Button>
                            : <Button onClick={()=> handleJoin()} variant="outlined" style={{border: "1px solid #0CC8A8", borderRadius: "5px", textTransform: "initial"}}>
                                    <Typography style={{ color: "#0CC8A8", fontFamily: "SFPro-Regular", fontSize: "13px" }}>+ Join Meeting Now</Typography>
                            </Button> }     
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
export default UppNav;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography: {
            body2: {
                fontFamily: 'Poppins, sans-serif'
            },
        },
        limitedText: {
            overflowWrap: "break-word",
            display: "-webkit-box",
            // height: "40px",
            fontSize: "14px",
            textAlign: "left",
            whiteSpace: "break-spaces",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden"
        }
    })
);