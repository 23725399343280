Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.jsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.audienceFollowersAPiEndPoint = "visual_analytics/audience_followers";
exports.userStatisticAPiEndPoint = "visual_analytics/user_statistic";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "visualanalytics";
exports.labelBodyText = "visualanalytics Body";
exports.joinMeetingAPI = "StartTeamsSession";
exports.getMeetingTypesURL = "GetSessionTypes";
exports.getRoleTypesURL = "RolesForSessionType";
exports.getMatricsURL = "GetMetrics"
// Customizable Area End