import React, { useCallback, useEffect, useState } from 'react';
import {
    Button,
    Link,
    Typography,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
const useStyles = makeStyles((theme) => ({
    paper: {
        // textAlign: "center"
        // [theme.breakpoints.only('xs')]: {
        //     padding: theme.spacing(0, 5),
        // },
        // [theme.breakpoints.only('sm')]: {
        //     padding: theme.spacing(0, 13),
        // },
        // [theme.breakpoints.only('md')]: {
        //     padding: theme.spacing(0, 9),
        // },
        // [theme.breakpoints.only('lg')]: {
        //     padding: theme.spacing(0, 12),
        // },
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        // marginTop: theme.spacing(1),
    },
   
    otpInputField: {
        width: '50px',
        height: "50px",
        marginRight:'15px',
        outline: "none",
        border: "1px solid #979797",
        borderRadius: "5px",
        background: "inherit",
        fontSize: '1.45rem',
        color: "#979797",
        textAlign: "center",
        [theme.breakpoints.only('xs')]: {
            width: '35px',
            height: '35px',
            fontSize: '14px',
            marginRight:'5px'
        },
        // [theme.breakpoints.only('md')]: {
        //     width: '40px',
        //     height: '40px',
        //     fontSize: '14px',
        //     marginRight:'8px'
           
        // },
    },
    // css for otpTitleText
    otpTitleText: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '35px',
        lineHeight: '43px',
        color: '#37006E',
        paddingBottom: '2rem',
        // [theme.breakpoints.down('sm')]: {
        //     fontSize: '25px',
        //     lineHeight: '33px',
        // },
    },
    otpContentText: {
        fontFamily: 'Montserrat',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '30px',
        textAlign: 'center',
        color: '#968C8C',
        marginBottom: '2rem',
        // [theme.breakpoints.only('xs')]: {
        //     fontSize: '12px',
        // },
        // [theme.breakpoints.between('sm', 'md')]: {
        //     fontSize: '16px',
        //     lineHeight: '25px',
        // },
    },
   
  
    otpPhoneNumber: {
        color: '#41479B',
    },
    numberChangeBtn: {
        color: '#BA68C8',
        fontSize: '11px',
        cursor: 'pointer',
    },
    modelContent: {
        padding: '2rem',
        borderRadius: '13px',
    },
}));
interface Props {
    handleOtpCall: any,
    handleResend: any
}
const OtpInputPage = (props: Props) => {
    const classes = useStyles();
    const [otp, setOtp] = useState(new Array(5).fill(''));
    const [counter, setCounter] = React.useState(60);
    const [otpError, setOtpError] = useState(false);
    const [open, setOpen] = React.useState(false);
    useEffect(() => {
        let otpTime = localStorage.getItem('otpTime')
        if (otpTime) {
            let time = (parseInt(otpTime) - 1);
            setCounter(time);
        }
    }, [])
    useEffect(() => {
        const timer: any = counter > 0 && setInterval(() => {
            localStorage.setItem('otpTime', JSON.stringify(counter))
            handleIncrement()
        }, 1000);
        return () => clearInterval(timer);
    }, [counter]);
    const handleIncrement = useCallback(() => {
        setCounter(counter - 1);
    }, [counter]);
    const handleChange = (
        element: { value: number; nextSibling: { focus: () => void } },
        index: number
    ) => {
        if (isNaN(element.value)) return false;
        setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
        setOtpError(false);
        //Focus next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };
    const handelOtp = (e: any) => {
        e.preventDefault();
        // sentOtpRequest(phoneNumValue)
        if (otp[0] === '' || otp[1] === '' || otp[2] === '' || otp[3] === '' || otp[4] === '') {
            setOtpError(true);
        } else {
            setOtpError(false);
            const phOtp = otp.join('');
            setOpen(true);
        }
    };
    const handelResendOtpRequst = () => {

    };
    const handelChangeNumber = () => {

    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleSignUp = () => {
        // otpVarification(otp.join(''));
        // setOpen(false);
        props.handleOtpCall(otp.join(''))

    }
    return (
        <div className={classes.paper}>
            <form onSubmit={handelOtp}  noValidate>
                {otp.map((data, index) => {
                    return (
                        <input
                            className={classes.otpInputField}
                            type="text"
                            name="otp"
                            maxLength={1}
                            key={index}
                            value={data}
                            onChange={(e: any) => handleChange(e.target, index)}
                            onFocus={(e: any) => e.target.select()}
                        />
                    );
                })}
                {/* {otpError && (
                   toast.error("Please Enter valid OTP")
                )} */}
            </form>


        </div>
    );
};
export default OtpInputPage;