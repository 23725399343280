import React from "react";
// Customizable Area Start
import JoinMeetingPageController, { Props } from "./JoinMeetingPageController";
import {
  withTheme,
  withStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";
import { Grid, Typography, Box, TextField, Button } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
// @ts-ignore
import bgImage from "../assets/bg.png";
// @ts-ignore
import sideImage from "../assets/sideImg.png";
// @ts-ignore
import logoImage from "../assets/logo.png";
// @ts-ignore
import mailImage from "../assets/Mail.png";
// @ts-ignore
import passwordImage from "../assets/Password.png";
import { Formik, ErrorMessage } from "formik";
import './JoinMeeting.css'
import * as Yup from "yup";

const emailReg = new RegExp("[a-z0-9._%+-]+@[a-z0-9]+.[a-z]{2,3}$");

const formikErrorMessage = (formik: any, fieldName: any) => {
  return formik.touched[fieldName] && formik.errors[fieldName] ? (
    <div style={{ color: "red", fontFamily: "SFPro-Regular", margin: "3px 0" }}>
      {formik.errors[fieldName]}
    </div>
  ) : null;
};

const formikErrorClassCheck = (formik: any, fieldName: any) => {
  return `form-control ${formik.errors[fieldName] ? "is-invalid" : formik.touched[fieldName] && !formik.errors[fieldName] ? "is-valid" : ""}`;
};
// Customizable Area End

class JoinMeetingPage extends JoinMeetingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes, navigation } = this.props;
    const { imageLoading, password, windowWidth } = this.state;
    const { signInErrMsg } = this.state;
    
    // Customizable Area End
    return (
      // Customizable Area Start
      <div className="manage_background">
        <div className="blur_effect"></div>
        <div className="main_wrapper" style={{zIndex:2}}>
          {windowWidth < 900 ? (
            <Grid container spacing={0} style={{ width: "100%" }}>
              <Grid
                item
                sm={12}
                xs={12}
                style={{
                  position: "relative",
                  padding: "3% 6%",
                  backgroundColor: "#1F1F1F",
                  borderRadius: "10px 10px 10px 10px",
                }}
              >
                <img
                  src={logoImage}
                  style={{ position: "absolute", top: "5%", left: "38%" }}
                  width="100px"
                  alt="No_image_found"
                />
                <FormBox password={password} signInAxios={this.signInAxios} boxheight="70vh" signInErrMsg={signInErrMsg} />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={0}>
              <Grid
                item
                sm={5}
                xs={12}
                style={{ position: "relative", height: "80vh" }}
              >
                <img
                  src={sideImage}
                  style={{ borderRadius: "10px 0 0 10px" }}
                  width="100%"
                  height="100%"
                  alt="No_image_found"
                />
                <img
                  src={logoImage}
                  style={{ position: "absolute", top: "5%", left: "30%" }}
                  width="40%"
                  alt="No_image_found"
                />
              </Grid>
              <Grid
                item
                sm={7}
                xs={12}
                style={{
                  height: windowWidth < 900 ? "100%" : "80vh",
                  backgroundColor: "#1F1F1F",
                  borderRadius: "0 10px 10px 0",
                }}
              >
              <FormBox password={password} signInAxios={this.signInAxios} boxheight="100%"  signInErrMsg={signInErrMsg} />
            </Grid>
            </Grid>
          )}
        </div>
      </div>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default JoinMeetingPage;


const FormBox  = ({password,signInAxios,boxheight, signInErrMsg}:{password: any,signInAxios: any,boxheight:any, signInErrMsg:any}) => {
  
  return (<Box
    style={{
      width: "100%",
      height: boxheight,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      enableReinitialize
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .matches(emailReg, "Email is not valid")
          // .email("Invalid email")
          .required("Email is required")
          .max(200, "maximum limit 200"),

         password: Yup.string().required('Password is required'),
          
      })}
      onSubmit={(values) => {
        signInAxios(values);
      }}
    >
      {(formikProps) => {
        const {
          values,
          // setFieldValue,
          handleChange,
          handleSubmit,
          touched,
        } = formikProps;
        return (
          <form
            onSubmit={handleSubmit}
            style={{ marginBottom: "1rem" , paddingLeft: "5%", paddingRight: "5%"}}
          >
            <Box>
              <Box style={{ marginBottom: "40px" }}>
                <Typography
                  style={{
                    color: "#ffffff",
                    fontSize: "25px",
                    fontFamily: "SFPro-Bold",
                    marginBottom: "1rem",
                  }}
                >
                  Welcome back
                </Typography>
                <Grid
                  container
                  spacing={3}
                  justifyContent="space-between"
                >
                  <Grid item md={12} xs={12}>
                    <Typography
                      style={{
                        fontFamily: "SFPro-Regular",
                        color: "#ffffff",
                        marginBottom: "0.6rem",
                        fontSize: "12px",
                      }}
                    >
                      Email address
                    </Typography>

                    <TextField
                      fullWidth
                      id="email"
                      name="email"
                      className={formikErrorClassCheck(
                        formikProps,
                        "email"
                      )}
                      value={values.email}
                      onChange={handleChange}
                      InputProps={{
                        className: 'input',
                        startAdornment: (
                          <Button
                            style={{
                              paddingLeft: "0px",
                              paddingRight: "0px",
                            }}
                            // onClick={() => this.showPassword(password)}
                          >
                            {/* {password ?
                                                                      <img src={crossEyeImage}
                                                                          style={{ height: '24px' }} />
                                                                      : */}
                            <img
                              src={mailImage}
                              style={{ height: "20px" }}
                            />
                            {/* } */}
                          </Button>
                        ),

                        disableUnderline: true,
                      }}
                      style={{
                        fontFamily: "SFPro-Regular",
                        fontSize: "16px",
                      }}
                      placeholder={"Please enter email id"}
                      
                    />
                    {formikErrorMessage(formikProps, "email")}
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <Typography
                      style={{
                        fontFamily: "SFPro-Regular",
                        color: "#ffffff",
                        marginBottom: "0.6rem",
                        fontSize: "12px",
                      }}
                    >
                      Password
                    </Typography>

                    <TextField
                      fullWidth
                      id="password"
                      name="password"
                      type={password ? "password" : "text"}
                      className={formikErrorClassCheck(
                        formikProps,
                        "password"
                      )}
                      value={values.password}
                      onChange={handleChange}
                      helperText={
                        <Typography style={{color:"red"}}>
                          {signInErrMsg !==""? (
                        <Typography
                            style={{
                                fontFamily: "SFPro-Regular",
                                color: "red", 
                            }}
                        >
                            {signInErrMsg}
                        </Typography>
                    ):<ErrorMessage name="password" />}

                            
                        </Typography>

                    }
                      InputProps={{
                        className: 'input',
                        startAdornment: (
                          <Button
                            style={{
                              paddingLeft: "0px",
                              paddingRight: "0px",
                            }}
                            // onClick={() => this.showPassword(password)}
                          >
                            {/* {password ?
                                                                      <img src={crossEyeImage}
                                                                          style={{ height: '24px' }} />
                                                                      : */}
                            <img
                              src={passwordImage}
                              style={{ height: "20px" }}
                            />
                            {/* } */}
                          </Button>
                        ),

                        disableUnderline: true,
                      }}
                      style={{
                        fontFamily: "SFPro-Regular",
                        fontSize: "16px",
                      }}
                      
                    />
                    
                  </Grid>
                </Grid>
              </Box>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Box>
                    <Button
                      type="submit"
                      style={{
                        backgroundColor: "#59F1D2",
                        color: "#000",
                        padding: ".3rem 1.8rem",
                        textTransform: "initial",
                        fontFamily: "SFPro-Regular",
                        borderRadius: "6px",
                        fontSize: "16px",
                        width: "100%",
                      }}
                    >
                      Sign In
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        );
      }}
    </Formik>
  </Box>)
}
// Customizable Area End
