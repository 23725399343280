import React from "react";
// Customizable Area Start
import ForgotPasswordController, { Props } from "./ForgotPasswordControlar";
import { withTheme, withStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Grid, Typography, Box, TextField, Button, FormControl, Select, MenuItem } from "@material-ui/core"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
// @ts-ignore
import bgImage from "../assets/bg.png";
// @ts-ignore
import sideImage from "../assets/sideImg.png"
// @ts-ignore
import logoImage from "../assets/logo.png"
// @ts-ignore
import crossEyeImage from "../assets/crossEye.png"
// @ts-ignore
import eyeImage from "../assets/eye.png"
// @ts-ignore
import mailImage from "../assets/Mail.png"
// @ts-ignore
import passwordImage from "../assets/Password.png"
import { Formik } from "formik";
import * as Yup from "yup";
import OtpInputPage from "../../../components/src/OtpInputPage";

const emailReg = new RegExp("[a-z0-9._%+-]+@[a-z0-9]+.[a-z]{2,3}$");

const formikErrorMessage = (formik: any, fieldName: any) => {
    return (formik.touched[fieldName] && formik.errors[fieldName]
        ? <div style={{ color: "red", fontFamily: "SFPro-Regular", margin: "3px 0" }}>{formik.errors[fieldName]}</div>
        : null);
}

const formikErrorClassCheck = (formik: any, fieldName: any) => {
    return `form-control ${formik.errors[fieldName] ? 'is-invalid' : formik.touched[fieldName] && !formik.errors[fieldName] ? 'is-valid' : ''}`;
}
// Customizable Area End

class ForgotPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, navigation } = this.props;
        const { imageLoading, password, isOTP, windowWidth } = this.state;

        // Customizable Area End
        return (
            // Customizable Area Start
            <div className={classes.manage_background}>
                <div className={classes.blur_effect} />
                <div className={classes.main_wrapper} style={{width: windowWidth < 1403 ? "80%" : "50%"}}>
                    {
                        windowWidth < 900 ?
                            <Grid container spacing={0} style={{width: "70vw"}}>
                                <Grid item sm={12} xs={12} style={{ position: "relative", padding: "3% 6%", backgroundColor: '#1F1F1F', borderRadius: "10px 10px 10px 10px" }} >
                                    <img src={logoImage} style={{ position: "absolute", top: "5%", left: "38%" }} width="100px" alt="No_image_found" />
                                    <Box style={{ width: "100%", height: "70vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Formik
                                            initialValues={{
                                                email: "",
                                            }}
                                            enableReinitialize
                                            validationSchema={Yup.object().shape({
                                                email: Yup.string()
                                                    .matches(emailReg, 'Email is not valid')
                                                    // .email("Invalid email")
                                                    .required("Email is required")
                                                    .max(200, "maximum limit 200"),
                                            })}
                                            onSubmit={(values, { resetForm }) => {
                                                this.setState({ isOTP: true })
                                                resetForm({
                                                    values: {
                                                        email: "",
                                                    }
                                                })
                                            }}
                                        >
                                            {formikProps => {
                                                const {
                                                    values,
                                                    // setFieldValue,
                                                    handleChange,
                                                    handleSubmit,
                                                    touched,
                                                    errors
                                                } = formikProps;
                                                return (
                                                    <form onSubmit={handleSubmit} style={{ marginBottom: "1rem" }}>
                                                        <Box>
                                                            <Box style={{ marginBottom: "13px" }}>
                                                                <Typography style={{ color: "#ffffff", fontSize: "25px", fontFamily: "SFPro-Bold", marginBottom: "1.5rem" }}>Forgot Password</Typography>
                                                                <Grid container spacing={3} justifyContent="space-between">
                                                                    {
                                                                        isOTP ?
                                                                            <Grid item sm={12} xs={12}>
                                                                                <Typography

                                                                                    style={{
                                                                                        fontFamily: "SFPro-Regular",
                                                                                        color: '#ffffff',
                                                                                        marginBottom: "0.6rem",
                                                                                        fontSize: '12px',
                                                                                    }}
                                                                                >
                                                                                    Enter OTP
                                                                                </Typography>
                                                                                <OtpInputPage handleOtpCall={""} handleResend={""} />
                                                                            </Grid>
                                                                            :
                                                                            <Grid item md={12} xs={12}>
                                                                                <Typography

                                                                                    style={{
                                                                                        fontFamily: "SFPro-Regular",
                                                                                        color: '#ffffff',
                                                                                        marginBottom: "0.6rem",
                                                                                        fontSize: '12px',
                                                                                    }}
                                                                                >
                                                                                    Registered Email
                                                                                </Typography>

                                                                                <TextField
                                                                                    fullWidth
                                                                                    id="email"
                                                                                    name="email"
                                                                                    className={formikErrorClassCheck(formikProps, "email")}
                                                                                    value={values.email}
                                                                                    onChange={handleChange}
                                                                                    InputProps={{
                                                                                        className: classes.input,
                                                                                        // startAdornment:
                                                                                        //     <Button style={{ paddingLeft: '0px', paddingRight: "0px" }}
                                                                                        //     >
                                                                                        //         <img src={mailImage}
                                                                                        //             style={{ height: '20px' }} />
                                                                                        //     </Button>,

                                                                                        disableUnderline: true
                                                                                    }}
                                                                                    style={{
                                                                                        fontFamily: "SFPro-Regular",
                                                                                        fontSize: "16px",

                                                                                    }}
                                                                                    placeholder={"gaurav@gmail.com"}
                                                                                />
                                                                                {formikErrorMessage(formikProps, "email")}
                                                                            </Grid>}

                                                                </Grid>
                                                            </Box>
                                                            <Grid container >
                                                                <Grid item xs={12} sm={12}>
                                                                    <Box>
                                                                        {
                                                                            isOTP ?
                                                                                <Button
                                                                                    type="submit"
                                                                                    style={{
                                                                                        backgroundColor: "#59F1D2",
                                                                                        color: "#000",
                                                                                        padding: ".3rem 1.8rem",
                                                                                        textTransform: "initial",
                                                                                        fontFamily: "SFPro-Regular",
                                                                                        borderRadius: '6px',
                                                                                        fontSize: "16px",
                                                                                        width: '100%',
                                                                                        marginTop: "1rem"
                                                                                    }}
                                                                                >
                                                                                    Submit
                                                                                </Button>
                                                                                :
                                                                                <Button
                                                                                    type="submit"
                                                                                    style={{
                                                                                        backgroundColor: "#59F1D2",
                                                                                        color: "#000",
                                                                                        padding: ".3rem 1.8rem",
                                                                                        textTransform: "initial",
                                                                                        fontFamily: "SFPro-Regular",
                                                                                        borderRadius: '6px',
                                                                                        fontSize: "16px",
                                                                                        width: '100%',
                                                                                        marginTop: "1rem"
                                                                                    }}
                                                                                >
                                                                                    Get OTP
                                                                                </Button>
                                                                        }
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </form>
                                                );
                                            }}
                                        </Formik>
                                    </Box>
                                </Grid>
                            </Grid>
                            :
                            <Grid container spacing={0} >
                                <Grid item sm={5} xs={12} style={{ position: "relative", height: "100%" }}>
                                    <img src={sideImage} style={{ borderRadius: "10px 0 0 10px", }} width="100%" height="550px" alt="No_image_found" />
                                    <img src={logoImage} style={{ position: "absolute", top: "5%", left: "30%" }} width="40%" alt="No_image_found" />
                                </Grid>
                                <Grid item sm={7} xs={12} style={{ height: windowWidth < 900 ? "100%" : "550px", padding: "3% 6%", backgroundColor: '#1F1F1F', borderRadius: "0 10px 10px 0" }} >
                                    <Box style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <Formik
                                            initialValues={{
                                                email: "",
                                            }}
                                            enableReinitialize
                                            validationSchema={Yup.object().shape({
                                                email: Yup.string()
                                                    .matches(emailReg, 'Email is not valid')
                                                    // .email("Invalid email")
                                                    .required("Email is required")
                                                    .max(200, "maximum limit 200"),
                                            })}
                                            onSubmit={(values, { resetForm }) => {
                                                this.setState({ isOTP: true })
                                                resetForm({
                                                    values: {
                                                        email: "",
                                                    }
                                                })
                                            }}
                                        >
                                            {formikProps => {
                                                const {
                                                    values,
                                                    // setFieldValue,
                                                    handleChange,
                                                    handleSubmit,
                                                    touched,
                                                    errors
                                                } = formikProps;
                                                return (
                                                    <form onSubmit={handleSubmit} style={{ marginBottom: "1rem" }}>
                                                        <Box>
                                                            <Box style={{ marginBottom: "13px" }}>
                                                                <Typography style={{ color: "#ffffff", fontSize: "25px", fontFamily: "SFPro-Bold", marginBottom: "1.5rem" }}>Forgot Password</Typography>
                                                                <Grid container spacing={3} justifyContent="space-between">
                                                                    {
                                                                        isOTP ?
                                                                            <Grid item sm={12} xs={12}>
                                                                                <Typography

                                                                                    style={{
                                                                                        fontFamily: "SFPro-Regular",
                                                                                        color: '#ffffff',
                                                                                        marginBottom: "0.6rem",
                                                                                        fontSize: '12px',
                                                                                    }}
                                                                                >
                                                                                    Enter OTP
                                                                                </Typography>
                                                                                <OtpInputPage handleOtpCall={""} handleResend={""} />
                                                                            </Grid>
                                                                            :
                                                                            <Grid item md={12} xs={12}>
                                                                                <Typography

                                                                                    style={{
                                                                                        fontFamily: "SFPro-Regular",
                                                                                        color: '#ffffff',
                                                                                        marginBottom: "0.6rem",
                                                                                        fontSize: '12px',
                                                                                    }}
                                                                                >
                                                                                    Registered Email
                                                                                </Typography>

                                                                                <TextField
                                                                                    fullWidth
                                                                                    id="email"
                                                                                    name="email"
                                                                                    className={formikErrorClassCheck(formikProps, "email")}
                                                                                    value={values.email}
                                                                                    onChange={handleChange}
                                                                                    InputProps={{
                                                                                        className: classes.input,
                                                                                        // startAdornment:
                                                                                        //     <Button style={{ paddingLeft: '0px', paddingRight: "0px" }}
                                                                                        //     >
                                                                                        //         <img src={mailImage}
                                                                                        //             style={{ height: '20px' }} />
                                                                                        //     </Button>,

                                                                                        disableUnderline: true
                                                                                    }}
                                                                                    style={{
                                                                                        fontFamily: "SFPro-Regular",
                                                                                        fontSize: "16px",

                                                                                    }}
                                                                                    placeholder={"gaurav@gmail.com"}
                                                                                />
                                                                                {formikErrorMessage(formikProps, "email")}
                                                                            </Grid>}

                                                                </Grid>
                                                            </Box>
                                                            <Grid container >
                                                                <Grid item xs={12} sm={12}>
                                                                    <Box>
                                                                        {
                                                                            isOTP ?
                                                                                <Button
                                                                                    type="submit"
                                                                                    style={{
                                                                                        backgroundColor: "#59F1D2",
                                                                                        color: "#000",
                                                                                        padding: ".3rem 1.8rem",
                                                                                        textTransform: "initial",
                                                                                        fontFamily: "SFPro-Regular",
                                                                                        borderRadius: '6px',
                                                                                        fontSize: "16px",
                                                                                        width: '100%',
                                                                                        marginTop: "1rem"
                                                                                    }}
                                                                                >
                                                                                    Submit
                                                                                </Button>
                                                                                :
                                                                                <Button
                                                                                    type="submit"
                                                                                    style={{
                                                                                        backgroundColor: "#59F1D2",
                                                                                        color: "#000",
                                                                                        padding: ".3rem 1.8rem",
                                                                                        textTransform: "initial",
                                                                                        fontFamily: "SFPro-Regular",
                                                                                        borderRadius: '6px',
                                                                                        fontSize: "16px",
                                                                                        width: '100%',
                                                                                        marginTop: "1rem"
                                                                                    }}
                                                                                >
                                                                                    Get OTP
                                                                                </Button>
                                                                        }
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                        </Box>
                                                    </form>
                                                );
                                            }}
                                        </Formik>
                                    </Box>
                                </Grid>
                            </Grid>
                    }
                </div>
            </div>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withTheme(
    withStyles((theme: Theme) =>
        createStyles({
            input: {
                width: "100%",
                height: "100%",
                border: "1px solid #ffffff",
                borderRadius: "7px",
                textDecoration: "none",
                fontSize: "1em",
                fontFamily: "SFPro-Regular",
                // backgroundColor: "transparent",
                color: '#ffffff !important',
                padding: "5px 1rem",
                "& > .MuiInputBase-input": {
                    height: "100%",
                },
                "&  .MuiFormHelperText-root.Mui-error": {
                    fontFamily: "SFPro-Regular",
                },
            },
            manage_background: {
                // backgroundColor: '#1f1f1f',
                // position: 'relative',
            },
            blur_effect: {
                position: 'absolute',
                width: '100%',
                height: '100vh',
                zIndex: 0,
                backgroundImage: `url(${bgImage})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                filter: 'blur(20px)'
            },
            main_wrapper: {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                // width: '496px',
                height: "70vh",
                // width: "80%",
                borderRadius: '6px',
                boxShadow: '24px',
                padding: '10px 5px',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            },
            customSelectWrapper: {
                position: "relative",
                width: "100%",
                height: "50px",
                fontFamily: "SFPro-Regular",
                // boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important",
                backgroundColor: "#000 !important",
                borderColor: "#ffffff !important",
                border: "1px solid #ffffff",
                // padding: "5px 0",
                borderRadius: "5px 5px 5px 5px",
                display: "flex",
                alignItems: "center",
                [theme.breakpoints.down('xs')]: {
                    padding: '0 0 2px 5px'
                },
                // marginBottom: "10px"
            },
            menuItemStyle: {
                textAlign: "right",
                color: '#ffffff !important',
                backgroundColor: '#000',

            },
            paper: {
                backgroundColor: "#000",
                height: "110px",
                border: "1px solid #ffffff",
                overflowY: "auto"
            },
            customArrow: {
                position: "absolute",
                top: 0,
                right: 0,
                display: "block",
                backgroundColor: "#000",
                // borderRight: "1px solid #ffffff",
                // borderRadius: "0 6px 6px 0",
                height: '100%',
                width: "3rem",
                pointerEvents: 'none',
            },
            select: {
                fontSize: "14px",
                fontFamily: "SFPro-Regular",
                backgroundColor: "#000",
                "& .MuiSelect-icon": {
                    zIndex: 1,
                    right: ".7rem",
                    color: "#8991a4",
                },
                "& .MuiSelect-select": {
                    color: "#fff !important",
                    backgroundColor: "#000 !important",
                    paddingLeft: "10px",
                },

            },


        }))(ForgotPassword)
)
// Customizable Area End
