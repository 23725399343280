import React from "react";
import {
    Grid,
    Typography,
    Box,
    Tabs,
    Tab,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    Modal,
} from "@material-ui/core"
import CustomBar from "../../components/src/Bar"

let participants: any = {};

const TableData2 = ({matricsResponse,emotionType,getFeeling2,participant_data,MyImage,eventType}: {eventType:any,matricsResponse: any, emotionType: any, getFeeling2: any,participant_data:any, MyImage:any}) => {
    const findMyParticipant = ()=>{
        let my_participant:any  = {};
        participant_data.map((ele:any)=> {      
          if(ele.id == matricsResponse.my_participation.participant_id){
              my_participant = ele;
          }
      })
      return my_participant
    }
    return (
       <>
        {participant_data.length!=0
        ?participant_data.map((row:any) => (
            <TableRow
                key={row.id}
            >
                <TableCell className="tableCellWidth" style={{ fontFamily: "SFPro-Regular", color: "white", backgroundColor: "#1c1b1b", borderBottom: "none", borderRight: "1px solid rgb(72 72 72)" }} align="center">
                        {/* @ts-ignore */}
                    <Box style={{ display: 'flex', alignItems: "center", gap: "5px" }}>
                        <img src={Object.keys(findMyParticipant()).length == 0 ? MyImage : `data:image/png;base64,${row.profile_image_bytes}`} height="30px" width="30px" style={{ borderRadius: "50%" }} />
                        <Typography style={{ fontSize: "13px", fontFamily: "SFPro-Regular" }}>{row.participant_name}</Typography>
                    </Box>
                </TableCell>
                <TableCell className="tableCellWidth" style={{ fontFamily: "SFPro-Regular", color: "white", backgroundColor: "#1c1b1b", borderBottom: "none", borderRight: "1px solid rgb(72 72 72)" }} align="center">
                        {/* @ts-ignore */}
                    <Box style={{ display: 'flex', alignItems: "center", gap: "5px" }}>
                    {getFeeling2(row.emotion_id)}
                        <Typography style={{ fontSize: "13px", fontFamily: "SFPro-Regular" }}>{emotionType.find((ele:any)=> {
                    if(parseInt(ele.id) === row.emotion_id) return ele
                        })?.name}</Typography>
                    </Box>
                </TableCell>
                <TableCell style={{ fontFamily: "SFPro-Regular", color: "white", backgroundColor: "#1c1b1b", borderBottom: "none", borderRight: "1px solid rgb(72 72 72)" }} align="left">
                    <CustomBar bgcolor={row.enrgy <= 50 ? "#f1776c" : '#0CC8A8'} progress={row.enrgy} height={20} align="horizontal" />
                </TableCell>
                <TableCell className="textlastChild" style={{ fontFamily: "SFPro-Regular", color: "white", backgroundColor: "#1c1b1b", borderBottom: "none", borderRight: "1px solid rgb(72 72 72)" }} align="left">
                {row?.tip?.text}
                </TableCell>
            </TableRow>
        )):<TableCell colSpan={4} className="textlastChild" style={{ textAlign:"center", fontFamily: "SFPro-Regular", color: "white", backgroundColor: "#1c1b1b", borderBottom: "none", borderRight: "1px solid rgb(72 72 72)" }} align="left"> no data</TableCell>
    }
       </>
    )
}
export default TableData2;