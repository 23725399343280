import { Box } from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
    withStyles,
} from "@material-ui/core/styles";
import React, { FunctionComponent, ReactNode } from "react";
// @ts-ignore
import meter1Image from "../../blocks/visualanalytics/assets/poor.png";
// @ts-ignore
import meter2Image from "../../blocks/visualanalytics/assets/ok.png";
// @ts-ignore
import meter3Image from "../../blocks/visualanalytics/assets/good.png";
// @ts-ignore
import meter4Image from "../../blocks/visualanalytics/assets/excellent.png";

// @ts-ignore
import needleImage from "../../blocks/visualanalytics/assets/Meter_Needle.png";
// @ts-ignore
import logoImage from "../../blocks/visualanalytics/assets/logo.png";
export interface MeterProps {
    level: number,
    energy: any
}


const CustomMeter = ({ level, energy }: MeterProps) => {
    const classes = useStyles();
    return (
        /* @ts-ignore */
        <Box style={{ width: "100%" }}>
            {/* @ts-ignore */}
            <Box style={{ position: "relative" }}>
            {energy <= 25? <img src={meter1Image} height="100px" width="100%" /> : energy<=50 ? <img src={meter2Image} height="100px" width="100%" />: energy<=75? <img src={meter3Image} height="100px" width="100%" /> : <img src={meter4Image} height="100px" width="100%" />}
                
                {/* @ts-ignore */}
                <Box className={classes.circle} >
                   
                    <img src={logoImage} height="15px" width="15px" style={{ borderRadius: "50%", margin: "2px" }} />
                </Box>
                {/* @ts-ignore */}
                <Box style={{ position: "absolute", left: "48%", top: "22%",transform: `rotate(${270+energy*1.8}deg)` }} className={classes.rotation}>
                    {/* <img src={needleImage} height="60px" width="100%" /> */}
                    {/* <p>N</p> */}
                </Box>
            </Box>

        </Box>
    )
}
export default CustomMeter;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        circle: {
            position: "absolute",
            left: "45%",
            bottom: "0",
            height: "20px",
            width: "20px",
            borderRadius: "50%",
            border: "2px solid white",
            // backgroundImage: `url(${logoImage})`,
            backgroundColor: "black",
            zIndex: 10,
        },
        rotation: {
            width: "0",
            height: "0",
            borderLeft: "6px solid transparent",
            borderRight: "6px solid transparent",
            borderBottom: "65px solid grey",
            // backgroundColor: "grey",
            transformOrigin: "bottom",
            transition: '.5s linear',
            transformStyle: 'preserve-3d'
        }
    })
);