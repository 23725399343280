import { Box, Grid, Typography, IconButton } from "@material-ui/core";
import {
    makeStyles,
    createStyles,
    Theme,
    withStyles,
} from "@material-ui/core/styles";
import React, { FunctionComponent, ReactNode, useState } from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import CustomBar from "./Bar";
import SingleBar from "./SingleBar"
// @ts-ignore
import engageImage from "../../blocks/visualanalytics/assets/engaging.png";
// @ts-ignore
import tiredImage from "../../blocks/visualanalytics/assets/tired.png";
// @ts-ignore
import participationImage from "../../blocks/visualanalytics/assets/participation.png";
import {Emojis} from "../../blocks/visualanalytics/src/assets";

interface ListBoxProps {
    listName: string;
    pImg: string;
    pName: string;
    feeling: string | undefined;
    progress: number | undefined;
    boxOpen: any[];
    handleBoxOpen: any;
}


const ListBox = ({ listName, pImg, pName, feeling, progress, boxOpen,handleBoxOpen }: ListBoxProps) => {
    const classes = useStyles();
    
    
   
    return (
        /* @ts-ignore */
        <Box style={{ marginBottom: "10px" }}>
            {/* @ts-ignore */}
            <Box style={{ border: "1px solid rgb(72 72 72)", borderRadius: "10px" }}>
                <List>
                <ListItem button onClick={() => handleBoxOpen(boxOpen)} classes={{root: classes.removePadding}}>
                        {/* @ts-ignore */}
                        <Box
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%'
                            }}
                        >
                            <Typography
                                style={{
                                    fontFamily: 'SFPro-Regular',
                                    fontSize: '16px',
                                    color: '#ffffff',
                                    marginRight: '40px'
                                }}
                            >
                                {listName}
                            </Typography>
                            {/* @ts-ignore */}
                            {listName=="My Participation" && boxOpen[1] ? <ExpandMoreRoundedIcon style={{ color: "white" }} fontSize="medium" /> : listName =="Current Speaker" && boxOpen[2]? <ExpandMoreRoundedIcon style={{ color: "white" }} fontSize="medium" /> : listName == "Previous Speaker" && boxOpen[3] ? <ExpandMoreRoundedIcon style={{ color: "white" }} fontSize="medium" /> : <ChevronRightRoundedIcon style={{ color: "white" }} fontSize="medium" />}
                        </Box>
                    </ListItem>
                </List>
                {/* @ts-ignore */}
                <Collapse in={listName=="My Participation"? boxOpen[1] : listName =="Current Speaker"? boxOpen[2]: boxOpen[3]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem style={{ color: "#ffffff", backgroundColor: "#1c1b1b", borderRadius: listName === "My Participation" ? "0 0 0 0" : "0 0 10px 10px" }}>
                            {/* @ts-ignore */}
                            <Box style={{ height: "70px", display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                            {/* @ts-ignore */}
                                <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                    <img src={pImg} height="70px" width="70px" style={{ borderRadius: "50%" }} />
                                    {/* @ts-ignore */}
                                    <Box>
                                        <Typography style={{ color: "white", fontFamily: "SFPro-Bold", fontSize: "16px", lineHeight: "1" }}>{pName}</Typography>
                                        <Typography style={{ color: "white", fontSize: "14px", fontFamily: "SFPro-Regular" }}>Participant</Typography>
                                        {
                                            feeling === "tired" ?
                                            /* @ts-ignore */
                                                <Box style={{ display: "flex", gap: "20px", alignItems: "center"}}>
                                                    <Typography style={{ color: "#f1776c", fontSize: "13px", fontFamily: "SFPro-Bold"}}>Tired</Typography>
                                                    {
                                                        listName === "My Participation" && <img src={participationImage} height="30px" width="30px" style={{ borderRadius: "50%" }} />
                                                    }
                                                </Box>
                                                :
                                                /* @ts-ignore */
                                                <Box style={{ display: "flex",gap: "20px", alignItems: "center"}}>
                                                    <Typography style={{ color: "#0CC8A8", fontSize: "13px", fontFamily: "SFPro-Bold"}}>{feeling}</Typography>
                                                    {
                                                        listName === "My Participation" && <img src={participationImage} height="30px" width="30px" style={{ borderRadius: "50%" }} />
                                                    }
                                                </Box>
                                        }

                                    </Box>
                                </Box>
                                {/* @ts-ignore */}
                                <Box style={{height: "100%"}}>
                                    {
                                        feeling === "tired" ?
                                        /* @ts-ignore */
                                            <Box style={{ display: "flex", gap: "10px", height: "100%", alignItems: "flex-end" }}>
                                                <img src={tiredImage} height="25px" width="25px" style={{ borderRadius: "50%" }} />
                                                <CustomBar bgcolor='#f1776c' progress='80' height={20} align="vartical" />
                                            </Box>
                                            :
                                            /* @ts-ignore */
                                            <Box style={{ display: "flex", gap: "10px", height: "100%", alignItems: "flex-end" }}>
                                                {
                                                    //@ts-ignore
                                                    <img src={Emojis[feeling]} height="25px" width="25px" style={{ borderRadius: "50%" }} />
                                                }
                                                <CustomBar bgcolor="#0CC8A8" progress={progress?.toString()} height={20} align="vartical" />
                                            </Box>
                                    }
                                </Box>
                            </Box>
                        </ListItem>
                        {
                            listName === "My Participation" ?
                                <ListItem style={{ backgroundColor: "#1c1b1b", borderRadius: "0 0 10px 10px" }}>
                                    {/* @ts-ignore */}
                                    <Box style={{ width: "100%" }}>
                                        <SingleBar bgcolor="#0cc8a8" progress={progress} height={20} />
                                    </Box>
                                </ListItem>
                                : null
                        }
                    </List>
                </Collapse>
            </Box>
        </Box>
    )
}
export default ListBox;

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        removePadding: {
            paddingTop: "0",
            paddingBottom: "0"
         }
    })
);