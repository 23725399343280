// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Emojis from "../../blocks/emojis/src/Emojis";
import Videos from "../../blocks/videos/src/Videos";
import LiveFeedCapture from "../../blocks/LiveFeedCapture/src/LiveFeedCapture";
import CfApi from "../../blocks/CfApi/src/CfApi";
import LiveStreaming from "../../blocks/LiveStreaming/src/LiveStreaming";
import Analytics from "../../blocks/analytics/src/Analytics";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import CfZoomMarketPlace from "../../blocks/CfZoomMarketPlace/src/CfZoomMarketPlace";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import CfMirroAi from "../../blocks/CfMirroAi/src/CfMirroAi";
import ThemeBlock from "../../blocks/themeblock/src/ThemeBlock";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import HdVideoFormats from "../../blocks/HdVideoFormats/src/HdVideoFormats";
import Sorting from "../../blocks/sorting/src/Sorting";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import Search from "../../blocks/search/src/Search";
import JoinMeeting from "../../blocks/analytics/src/JoinMeetingPage.web";
import ForgotPassword from "../../blocks/analytics/src/ForgotPassword.web";
import DashboardPage from "../../blocks/visualanalytics/src/Dashboard.web"
import JoinMeetingPageWeb from "../../blocks/analytics/src/JoinMeetingPage.web";


const routeMap = {
  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  Emojis: {
    component: Emojis,
    path: "/Emojis"
  },
  Videos: {
    component: Videos,
    path: "/Videos"
  },
  LiveFeedCapture: {
    component: LiveFeedCapture,
    path: "/LiveFeedCapture"
  },
  CfApi: {
    component: CfApi,
    path: "/CfApi"
  },
  LiveStreaming: {
    component: LiveStreaming,
    path: "/LiveStreaming"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  PostCreation: {
    component: PostCreation,
    path: "/PostCreation"
  },
  Posts: {
    component: Posts,
    path: "/Posts"
  },
  PostDetails: {
    component: PostDetails,
    path: "/PostDetails"
  },
  CfZoomMarketPlace: {
    component: CfZoomMarketPlace,
    path: "/CfZoomMarketPlace"
  },
  Settings5: {
    component: Settings5,
    path: "/Settings5"
  },
  CfMirroAi: {
    component: CfMirroAi,
    path: "/CfMirroAi"
  },
  ThemeBlock: {
    component: ThemeBlock,
    path: "/ThemeBlock"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  HdVideoFormats: {
    component: HdVideoFormats,
    path: "/HdVideoFormats"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  Notificationsettings: {
    component: Notificationsettings,
    path: "/Notificationsettings"
  },
  Search: {
    component: Search,
    path: "/Search"
  },
  JoinMeeting: {
    component: JoinMeeting,
    path: "/Login"
  },
  DashboardPage: {
    component: DashboardPage,
    path: "/DashboardPage"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  HomePage: {
    component: JoinMeeting,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;