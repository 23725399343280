import React from 'react'

interface BarProps {
    bgcolor: string;
    progress?: number;
    height: number;
    align?: string;
}

const SingleBar = ({ bgcolor, progress, height }: BarProps) => {
    const nonProgress = progress && 100 - progress;

    const Parentdiv = {
        height: height,
        width: '100%',
        backgroundColor: '#4a69cf',
        borderRadius: "5px",
        textAlign: "right",
        position: "relative"
    }

    const Childdiv = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: bgcolor,
        borderRadius: "5px 0 0 5px",
        textAlign: 'left',
        // position: "absolute"
    }

    const progresstext = {
        padding: 10,
        color: 'black',
        fontFamily: "SFPro-Regular"
    }

    return (
        // @ts-ignore
        <div style={Parentdiv}>
            <p style={{ color: "black", position: "absolute", right: "10px", top: "-16px", fontFamily: "SFPro-Regular" }}>{`${nonProgress}%`}</p>
            {/* @ts-ignore */}
            <div style={Childdiv}>
                <span style={progresstext}>{`${progress}%`}</span>
            </div>
        </div>
    )
}

export default SingleBar;