import React from "react";
import {
    Typography,
    TableRow,
    TableCell,
} from "@material-ui/core"

const TableData = ({matricsResponse,eventType,findIcon,analystcs_data}: {matricsResponse: any, eventType: any, findIcon: any,analystcs_data:any}) => {
    return (
       <>
        {analystcs_data.slice().reverse().map((element:any, index: number)=>{
            return (<TableRow key={index}>
            <TableCell style={{ fontFamily: "SFPro-Regular", color: "white", backgroundColor: "#1c1b1b", borderBottom: "none", borderRight: "1px solid rgb(72 72 72)" }} align="center">{(element.original_event_time).substring(0,9)}</TableCell>
            <TableCell className="minwidth160" style={{ fontFamily: "SFPro-Regular", color: "white", minWidth: "160px", backgroundColor: "#1c1b1b", borderBottom: "none", borderRight: "1px solid rgb(72 72 72)" }} align="left">
                <Typography style={{ fontSize: "13px", fontFamily: "SFPro-Regular" }}>{eventType.find((ele:any)=> {
                    if(parseInt(ele.id) === element.event_type) return ele
                        })?.name}</Typography>
            </TableCell>
            <TableCell style={{ fontFamily: "SFPro-Regular", color: "white", backgroundColor: "#1c1b1b", borderBottom: "none", borderRight: "1px solid rgb(72 72 72)" }} align="center">
                {findIcon(element.event_type)}
            </TableCell>
            <TableCell style={{ fontFamily: "SFPro-Regular", color: "white", backgroundColor: "#1c1b1b", borderBottom: "none" }} align="left">
                <Typography className="textlastChild" style={{ fontSize: "13px", fontFamily: "SFPro-Regular" }}>
                    {element.text}
                </Typography>
            </TableCell>
        </TableRow>)
        })}
       </>
    )
}
export default TableData;