import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { Images, Emojis} from "./assets"
import Api from "../../../components/src/API";

import {
  JoinMeetingI,
  MatrixI,
  MeetingTypeI,
  AnalyticsI,
  EventTypeI,
} from "./Interface";
import axios from "axios";
export const BaseURL = require("../../../framework/src/config");

const typeBtn = [
  {
      id: 0,
      title: 'notes'
  },
  {
      id: 1,
      title: 'participants'
  },
]
let localMeetKey:any ="";
// Customizable Area End
export const configJSON = require("./config.js");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  boxOpen: any[];
  boxEngOpen:any;
  localMeet:any;
  dummyLocalMeet:any;
  imageLoading: boolean;
  password: boolean;
  meetingType: string;
  roleType: string;
  mobileOpen: boolean;
  open: any;
  tabs: number;
  anchorEl: null | HTMLElement;
  join: boolean;
  meetingStatus: boolean;
  typeErrMsg: string;
  roleErrMsg: string;
  joinErrMsg: string;
  matrixErrMsg: string;
  loading: boolean;
  joinMeetingResponse: JoinMeetingI;
  leaveMeetingResponse: {
    end_meeting_event: string;
  };
  leaveErrMsg: string;
  matricsResponse: MatrixI;
  analyticsResponse: AnalyticsI;
  analyticsErr: string;
  meetingTypes: MeetingTypeI[];
  roleTypes: MeetingTypeI[];
  eventType: EventTypeI[];
  eventTypeErr: string;
  emotionType: EventTypeI[];
  emotionTypeErr: string;
  participantResponse: any;
  participantErr: string;
  analystcs_data: any[];
  participant_data:any[];
  showTooltip: boolean;
  acceptedTAndC: boolean;
  disclaimerSwitch:boolean;
  isSwitchDisabled:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  joinMeetingCallId: any;
  meetingTypeCallId: any;
  meetingRoleCallId: any;
  matricsCallId: any;
  currentInterval: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // getName(MessageEnum.NavigationToSingleArticle),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      boxOpen: [{1:false},{2:false},{3:false}],
      boxEngOpen:false,
      localMeet:[],
      dummyLocalMeet:[],
      imageLoading: true,
      password: true,
      meetingType: "sales_call",
      roleType: "main_presenter",
      mobileOpen: false,
      open: {},
      tabs: 0,
      anchorEl: null,
      join: false,
      meetingStatus: false,
      typeErrMsg: "",
      roleErrMsg: "",
      joinErrMsg: "",
      matrixErrMsg: "",
      loading: false,
      showTooltip: false,
      acceptedTAndC: false,
      disclaimerSwitch:false,
      isSwitchDisabled:false,
      joinMeetingResponse: {
        port: -1,
        meeting_name: "20221108-teams-session",
      },
      leaveMeetingResponse: {
        end_meeting_event: "",
      },
      leaveErrMsg: "",
      eventTypeErr: "",
      analyticsErr: "",
      meetingTypes: [],
      roleTypes: [],
      eventType: [],
      emotionType: [],
      emotionTypeErr: "",
      analyticsResponse: {
        analytics: [
          {
            event: {
              original_event_time: "",
              event_type: 1,
              text: "",
            },
          },
        ],
      },
      matricsResponse: {
        total_participants: 0,
        session_duration_seconds: 0,
        meetingId: "",
        periodSeconds: 0,
        role: "",
        streamStatus: "",
        average_engagement: 0,
        averageEnergy: 0,
        most_engaged_participants: {
          participant_id: [],
        },
        least_engaged_participants: {
          participant_id: [],
        },
        current_speaker: {
          energy_level: 0,
          emotion_id: 0,
          participant_id: 0,
        },
        previous_speaker: {
          energy_level: 0,
          emotion_id: 0,
          participant_id: 0,
        },
        my_participation: {
          energy_level: 0,
          emotion_id: 0,
          participant_id: 0,
          engagement_percentile: 0,
          tip: {
            text: "",
            tipType: 0,
          },
          anaytics: [
            {
              event: {
                original_event_time: "",
                event_type: 0,
                text: "",
              },
            },
          ],
        },
        visual: {
          fps: 0,
          fpsMaWindow: 0,
          engagement: {
            shouldersInframePercent: 0,
            faceFullyinviewPercent: 0,
            gazeIscamerarelevantPercent: 0,
            eyesNotreadingPercent: 0,
          },
          energy: {
            smilePresentPercent: 0,
            yawnDetectedPercent: 0,
            headNodsHorizontalPercent: 0,
            headNodsVerticalPercent: 0,
          },
          autonomic: {
            blinkCount: 0,
            blinkBetweenMsMean: 0,
            blinkBetweenMsSd: 0,
            blinkDurationMsMean: 0,
            blinkDurationMsSd: 0,
          },
        },
      },
      participantResponse: [{
          "id": 0,
          "initiating_user_id": 0,
          "participant_name": "Gaurav (Participant)",
          "session_role_id": 0,
          "profile_image_bytes": Images.MyImage
    }],
      participantErr: "",
      analystcs_data: [],
      participant_data:[],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    
    // Customizable Area End
  }
  // Customizable Area Start
  handleTabsChange = (event: any, newValue: any) => {
    this.setState({ tabs: newValue })
}

findIcon = (id: any) => {
    let splitedStr = "";
    this.state.eventType.forEach(element => {
        if (parseInt(element.id) === id) splitedStr = element?.name.split(" ").join('')
    })
    return <img src={Images[splitedStr as keyof typeof Images]} height="30px" width="30px" />
}

getFeeling2 = (id: number) => {
  let splitedStr = "";
    this.state.emotionType.forEach(element => {
        if (parseInt(element.id) === id) splitedStr = element?.name.split(" ").join('')
    })
    return <img src={Emojis[splitedStr as keyof typeof Emojis]} height="30px" width="30px" />
}
getFeeling = (id: number) => {
   const emotionDetail =  this.state.emotionType.find(emotion => {
        if (parseInt(emotion.id) === id) return emotion
    })
    if (emotionDetail) return emotionDetail?.name
    else return " ";
}
findMyParticipant = ()=>{
  let my_participant:any  = {};
  this.state.participantResponse.map((ele:any)=> {
            
    if(ele.id == this.state.matricsResponse.my_participation.participant_id){
        my_participant = ele;
    }
})
return my_participant
}
findMyCurrentSpeaker = ()=>{
  let current_speaker_data:any  = {};
  this.state.participantResponse.map((ele:any)=> {
    if(ele.id == this.state.matricsResponse.current_speaker.participant_id){
      current_speaker_data = ele;
    }
})
return current_speaker_data
}
findMyPreviousSpeaker = ()=>{
  let previous_speaker_data:any  = {};
  this.state.participantResponse.map((ele:any)=> {
            
    if(ele.id == this.state.matricsResponse.previous_speaker.participant_id){
      previous_speaker_data = ele;
    }
})
return previous_speaker_data
}
findMostEngagedImages = () =>{
  let arr: any[] = [];
  this.state.matricsResponse.most_engaged_participants.participant_id.map((element: number)=> {
    this.state.participantResponse.map((ele:any)=>{
      if(element == ele.id){
         return [...arr,arr.push(`data:image/png;base64,${ele.profile_image_bytes}`)]
      }
    })
  })
  return arr
}

findLeastEngagedImages = () =>{
  let arr: any[] = [];
  this.state.matricsResponse.least_engaged_participants.participant_id.map((element: number)=> {
    this.state.participantResponse.map((ele:any)=>{
      if(element == ele.id){
         return [...arr,arr.push(`data:image/png;base64,${ele.profile_image_bytes}`)]
      }
    })
  })
  return arr
}
  async componentDidMount() {
    let localMeet:any = localStorage.getItem("userDetails") || "[]"
    localMeetKey = JSON.parse(localStorage.getItem("userDetails") || "[]")[0]['apikey'];
    this.setState({localMeet:JSON.parse(localMeet),dummyLocalMeet:localMeetKey})
    this.getMatrix()
    this.getAnalytics();
    this.getEventTypes();
    this.getEmotionTypes();
    this.getMeetingType();
  }

  async componentWillUnmount() {
    if (this.currentInterval) {
      clearInterval(this.currentInterval);
    }
  }

  // Customizable Area End

  // Customizable Area Start

  getMeetingType = async () => {
    await axios
      .get(
        "https://pd-mirro-api.gentledune-75b0834d.eastus.azurecontainerapps.io/GetSessionTypes",
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": localMeetKey,
          },
          params: {
            session_type_id: 0,
          },
        }
      )
      .then((response: any) => {
        if (response.data.length)
          this.setState({
            meetingTypes: response.data,
            typeErrMsg: "",
            loading: false,
          });
        else
          this.setState({
            meetingTypes: [],
            typeErrMsg: "Data not found",
            loading: false,
          });
      })
      .catch((error: any) => {
        this.setState({
          typeErrMsg: error,
          loading: false,
        });
      });
  };

  getRoleType = async (id:number) => {
    //console.log(id, "397")
    await axios
      .get(
        "https://pd-mirro-api.gentledune-75b0834d.eastus.azurecontainerapps.io/RolesForSessionType",
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": this.state.dummyLocalMeet
          },
          params: {
            session_type_id: id
          }
        }
      )
      
      .then((response: any) => {
        /*istanbul ignore next */
        if (response.data.length)
          this.setState({
            roleTypes: response.data,
            roleErrMsg: "",
            loading: false,
            
          });
        else
          this.setState({
            roleTypes: [],
            roleErrMsg: "Role not found",
            loading: false,
          });
      })
      .catch((error: any) => {
        this.setState({
          roleErrMsg: error,
          loading: false,
        });
      });
  };

  joinMeetingAxios = async (values: any) => {
    this.setState({ loading: true });
    await axios
      .get(
        "https://pd-mirro-api.gentledune-75b0834d.eastus.azurecontainerapps.io" +
          `/StartTeamsSession`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": this.state.dummyLocalMeet,
          },
          params: {
            session_type_id: values.type_id,
            session_role_id: values.role_id,
            session_url: values.meetingLink,
          },
        }
      )
      .then((response: any) => {
        this.setState({
          loading: true,
          joinMeetingResponse: response.data,
          join: false,
          meetingStatus: true
        });
        this.handleInterval()
      })
      .catch((error: any) => {
        this.setState({
          loading: true,
          joinErrMsg: error,
          meetingStatus: false
        });
      });
  };

  leaveMeetingAxios = async () => {
    await axios
      .get(
        "https://pd-mirro-api.gentledune-75b0834d.eastus.azurecontainerapps.io" +
          `/EndTeamsSession`,
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": this.state.dummyLocalMeet,
          },
          params: {
            unique_processing_event_name: "https://builder.zoom.us/j/97290848358",
          },
        }
      )
      .then((response: any) => {
        this.setState({
          loading: true,
          leaveMeetingResponse: response.data,
          join: false,
          meetingStatus: false,
          analystcs_data:[],
        });
        /*istanbul ignore next */
        this.getMatrix()
        clearInterval(this.currentInterval)
      })
      .catch((error: any) => {
        this.setState({
          loading: true,
          leaveErrMsg: error,
          meetingStatus: true
        });
      });
      this.setState({
        acceptedTAndC: false,
        disclaimerSwitch:false,
        isSwitchDisabled:false,
      })
  };

  handleInterval = () => {
    this.getMatrix();
    this.GetParticipantInfoByMeetingName();
    this.BotMockJoinSession();
    this.currentInterval = setInterval(() => this.getMatrix(), 5000);
  };

  BotMockJoinSession = async (): Promise<void> => {
    await axios.post(
      "https://preprod-mirro-bot.engineer.ai:9441/joincall",
      {
        "JoinURL": "https://teams.microsoft.com/l/meetup-join/19%3ameeting_MTg5MTkwZWUtMzY2Ny00NmU2LTg5N2QtYWM2MmFkZGQ4MWY1%40thread.v2/0?context=%7b%22Tid%22%3a%223a41a237-f9cc-4e10-b8c0-6f5ccedab4e4%22%2c%22Oid%22%3a%22719bd5c9-40a0-41f2-ba1f-17b52faa7c13%22%7d",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };


  getMatrix = async () => {
    await axios
      .get(
        "https://pd-mirro-api.gentledune-75b0834d.eastus.azurecontainerapps.io/GetMetrics",
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": localMeetKey,
          },
          params: {
            processing_event_name: '20221108-teams-session',
          },
        }
      )
      .then((response: any) => {
        this.getParticipant(response.data[0])
        this.setState({
          loading: true,
          matricsResponse: response.data[0],
          analystcs_data: [...this.state.analystcs_data,response.data[0].my_participation.anaytics[0].event],
        });
      })
      .catch((error: any) => {
        this.setState({
          loading: true,
          matrixErrMsg: error,
        });
      });
  };
  getParticipant = (obj:any)=>{
    let myPrty:any = this.state.participant_data;
    /*istanbul ignore next */
    let subParty:any = myPrty.map((item:any)=>{
      if(item.id === obj.my_participation.participant_id){
        item.enrgy = obj.my_participation.energy_level
        item.emotion_id = obj.my_participation.emotion_id
        item.tip = obj.my_participation.tip
        
         }
         if(item.id === obj.current_speaker.participant_id){
           item.enrgy = obj.current_speaker.energy_level
           item.emotion_id = obj.current_speaker.emotion_id
       }
       if(item.id === obj.previous_speaker.participant_id){
         item.enrgy = obj.previous_speaker.energy_level
         item.emotion_id = obj.previous_speaker.emotion_id
     }
     if(item.id === obj.participant.participant_id){
      item.enrgy = obj.participant.energy_level
      item.emotion_id = obj.participant.emotion_id
      }
     return item
    })
    this.setState({participant_data:subParty})
  }
  getAnalytics = async () => {
    await axios
      .get(
        "https://pd-mirro-api.gentledune-75b0834d.eastus.azurecontainerapps.io/GetAnalytics",
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": localMeetKey,
          },
          params: {
            processing_event_name: this.state.joinMeetingResponse.meeting_name,
          },
        }
      )
      .then((response: any) => {
        this.setState({
          loading: true,
          analyticsResponse: response.data,
        });
      })
      .catch((error: any) => {
        this.setState({
          loading: true,
          analyticsErr: error,
        });
      });
  };
  getEventTypes = async () => {
    await axios
      .get(
        "https://pd-mirro-api.gentledune-75b0834d.eastus.azurecontainerapps.io/GetEventTypes",
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": localMeetKey,
          },
        }
      )
      .then((response: any) => {
        this.setState({
          loading: true,
          eventType: response.data,
        });
      })
      .catch((error: any) => {
        this.setState({
          loading: true,
          eventTypeErr: error,
        });
      });
  };

  getEmotionTypes = async () => {
    await axios
      .get(
        "https://pd-mirro-api.gentledune-75b0834d.eastus.azurecontainerapps.io/GetEmotionTypes",
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": localMeetKey,
          },
        }
      )
      .then((response: any) => {
        this.setState({
          loading: true,
          emotionType: response.data,
        });
      })
      .catch((error: any) => {
        this.setState({
          loading: true,
          emotionTypeErr: error,
        });
      });
  };

  GetParticipantInfoByMeetingName = async () => {
    await axios
      .get(
        "https://pd-mirro-api.gentledune-75b0834d.eastus.azurecontainerapps.io/GetParticipantInfoBySessionName",
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": localMeetKey,
          },
          params: {
            processing_event_name: "20221108-teams-session",
          },
        }
      )
      .then((response: any) => {
        this.respo(response)
      })
      .catch((error: any) => {
        this.setState({
          participantErr: error,
          loading: false,
        });
      });
  };
  respo = (response:any)=>{
          this.setState({
            participantResponse: response.data.participants,
            participant_data:response.data.participants,
            participantErr: "",
            loading: false,
          });
  }
  handleImageLoading = () => {
    this.setState({ imageLoading: false });
  };

  showPassword = (passoword: boolean) => {
    this.setState({ password: !passoword });
  };

  handleMeetingTypeChange = (event: any) => {
    this.setState({ meetingType: event.target.value as string });
  };

  handleRoleTypeChange = (event: any) => {
    this.setState({ roleType: event.target.value as string });
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleClick = (id: any) => {
    //@ts-ignore
    this.setState((prev_state) => {
      return {
        open: { ...prev_state.open, [id]: !prev_state.open[id] },
      };
    });
  };

  handleLogout = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = (type: any) => {
    if (type == "logout") {
      this.setState({ anchorEl: null });
      localStorage.clear();
    } 
  };

  handleJoin = () => {
    const { acceptedTAndC } = this.state;
    if (acceptedTAndC) {
      this.setState({ join: true });
      this.setState({boxOpen:[{1: true},{2: true},{3:true}]});
      this.handleBoxEngOpen()

    } else {
      this.setState({ showTooltip: true });
    }
  };
  handleSwitchChange = async(event: any) =>{
    this.setState({ ...this.state, [event.target.name]: event.target.checked });
    this.setState({
      isSwitchDisabled:true,
    })
    setTimeout(() => { 
        this.setState({ 
          showTooltip: false,
          acceptedTAndC: true,
          
        });
      }, 1000)
  }
  calcelJoin = () => {
    this.setState({ join: false });

    this.setState({
      acceptedTAndC: false,
      disclaimerSwitch:false,
      isSwitchDisabled:false,
    })
  };
  handleBoxEngOpen =()=>{
    this.setState({boxEngOpen:!this.state.boxEngOpen})
  }
  handleBoxOpen = (openBox: object) => {
    /*istanbul ignore next */
    this.state.boxOpen.map((ele: any, index: number)=>{
  
      if((Object.keys(ele))[0] == (Object.keys(openBox))[0]){
        let array: any[] = [...this.state.boxOpen]
        let obj: any = {...this.state.boxOpen[index]}
        let vge: any = Object.keys(ele)[0];
        obj[vge] = !(Object.values(ele)[0]);
        array[index] = obj;
        this.setState({boxOpen: array})
      }
    })
    
};
  // Customizable Area End
}
